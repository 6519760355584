import { useMemo } from 'react';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { BatchHttpLink } from 'apollo-link-batch-http';

export const APOLLO_STATE_PROPERTY_NAME = '__APOLLO_STATE__';
export const COOKIES_TOKEN_NAME = 'jwt';

let apolloClient = null;

const createApolloClient = (ctx) => {
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: 8000,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => {
        return !!error && error.networkError;
      },
    },
  });

  const batchHttpLink = new BatchHttpLink({
    uri: process.env.NEXT_PUBLIC_STRAPI_GRAPHQL_URL,
    credentials: 'same-origin', 
    batchInterval: 10, 
    batchMax: 10, 
  });

  const authLink = setContext((_, { headers }) => {

    return {
      headers: {
        ...headers,
        // authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  const link = ApolloLink.from([retryLink, authLink, batchHttpLink]);

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache(),
  });
};

export function initializeApollo(initialState = null, ctx = null) {
  const client = apolloClient ?? createApolloClient(ctx);

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return client;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = client;
  }

  return client;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROPERTY_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);

  return store;
}
